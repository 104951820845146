@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
  @import url('https://fastly.jsdelivr.net/gh/wanteddev/wanted-sans@v1.0.1/packages/wanted-sans/fonts/webfonts/variable/split/WantedSansVariable.min.css');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');
  @import url('tldraw/tldraw.css');

  html {
    font-family: 'Pretendard', 'Inter', 'Wanted-Sans', ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: #171719;
  }
  /* 
  body {
    font-family: 'Inter';
  } */

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .no-scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #074aec, #171719);
    border-radius: 100px;
  }
  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  .drag-none {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  /*익스플로러 기본 화살표 없애기*/
  select::-ms-expand {
    display: none;
  }
  /*화살표 기본 css 없애기*/
  select {
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input:focus {
    outline: none;
  }

  input[type='file'] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }

  .shadow-blur {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 10px 20px 0px rgba(0, 0, 0, 0.1),
      0px 15px 30px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10px);
  }
}

@layer components {
  .box-header {
    @apply min-h-[70px] flex px-20 py-12 bg-blue-100 rounded-t-8 items-center;
  }
  .box-header-title {
    @apply select-none text-title3-bold text-text-light-strong;
  }
  .box-body {
    @apply flex flex-col gap-24 px-20 py-40 border bg-background-light border-border-light rounded-b-8;
  }
  .button-quit {
    @apply bg-danger-base rounded-6 pt-[11px] pb-[10px] pl-[47px] pr-[48px] text-body1-semibold text-white select-none cursor-pointer;
  }
  .button-normal {
    @apply flex text-body2-medium text-text-light-sub items-center justify-center px-12 py-8 text-center border bg-background-light border-border-light rounded-8 gap-10 select-none cursor-pointer;
  }
  .notice-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, minmax(20px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));
    grid-auto-flow: row dense;
  }
}
